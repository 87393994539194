
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';


import { Alert, Container } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import SnackbarFeedback from '../components/SnackbarFeedback';
import { AppConfigContext } from '../context';
import BackendApiService from '../service/BackendApiService';
import UserEdit from './UserEdit';
import UserList from './UserList';
import { buildListZoneDcDv } from '../Utils/utils';
import ShowLoading from '../components/ShowLoading';
import { ADMIN, DIRECTEUR_COMMERCIAL_TRANSACTION } from '../Constantes';


function UserAdmin() {

    const appConfig = useContext(AppConfigContext);
    const { authState } = useOktaAuth();

    const [editMode, setEditMode] = useState(false);

    const [selectedUser, setSelectedUser] = useState(null);

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [feedback, setFeedback] = useState(null);
    const [loading, setLoading] = useState(false);
    const [zonesDcDv, setZonesDcDv] = useState([]);

    const service = useMemo(() => new BackendApiService(appConfig.app.backendApiUrl, authState), [appConfig.app.backendApiUrl, authState]);

    const handleClickEditUser = (user) => {
        setSelectedUser(user);
        setEditMode(true);
    }

    const exitEditMode = () => {
        setEditMode(false);
    }

    const handleCloseSnackbar = function () {
        setOpenSnackbar(false);
    }

    const handleOpenSnackbar = useCallback((feedback) => {
        setFeedback(feedback);
        setOpenSnackbar(true);
    }, []);

   
    const profil = authState.accessToken.profil?.profil;

    useEffect(() => {
      const fetchData = async () => {
        setLoading(true);
        try {
          const res = await service.fetchAgencesTransacWithZoneDc();

          if (!res.ok) {
            const errorStatus = res.status;
            const errorText = await res.text();
            throw new Error(errorStatus + ' ' + errorText);
          }

          const data = await res.json();
          setZonesDcDv(buildListZoneDcDv(data));
        } catch (error) {
          handleOpenSnackbar({
            severity: 'error',
            msg: 'Erreur survenue: ' + error.message,
          });
        } finally {
          setLoading(false);
        }
      };
      
      if ([DIRECTEUR_COMMERCIAL_TRANSACTION, ADMIN].includes(profil)) {
        fetchData();
      }
    }, [service, handleOpenSnackbar, profil]);

    const zonesDc =
      zonesDcDv.map((item) => ({
        name: item.zoneDc,
        id: item.zoneDcId,
      })) || [];


    if (!authState || !authState.isAuthenticated) return null;

    if (profil !== 'admin')
        return <Alert severity="warning">Votre profil ne permet pas d'afficher cette page</Alert>;

    return <Container>
        {editMode &&
            <UserEdit
                exitEditMode={exitEditMode}
                selectedUser={selectedUser}
                service={service}
                handleOpenSnackbar={handleOpenSnackbar}
                zonesDcDv={zonesDcDv}
            />
        }

        {!editMode &&
            <>
                <ShowLoading loading={loading}></ShowLoading>
                <UserList
                    handleClickEditUser={handleClickEditUser}
                    handleOpenSnackbar={handleOpenSnackbar}
                    service={service}
                    zonesDc= {zonesDc}
                />
            </> 
        }

        <SnackbarFeedback
            openSnackbar={openSnackbar}
            handleCloseSnackbar={handleCloseSnackbar}
            feedback={feedback}
        />

    </Container>;
}

export default UserAdmin;