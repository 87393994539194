import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { AppConfigProvider } from './context';
import { createTheme, ThemeProvider } from '@mui/material';

const root = ReactDOM.createRoot(document.getElementById('root'));

const theme = createTheme({
  palette: {
    gl: {
      main: '#FEF0D7',
      dark: '#F4BF67',
    },
    copro: {
      main: '#D3EAE0',
      dark: '#65B190',
    },
    tr: {
      main: 'lightSteelBlue',
      dark: 'steelblue',
    },
  },
});

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <AppConfigProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AppConfigProvider>
    </ThemeProvider>
  </React.StrictMode>
);
