import { Button, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { useState } from "react";

export default function RemplacerCdDialog(props) {
    const { onClose, open, chargeDevList, currentChargeDev } = props;

    const [newChargeDevId, setNewChargeDevId] = useState(null);

    const handleClose = () => {
        onClose(newChargeDevId);
    };

    const remplacants = chargeDevList.filter(cd => cd.idIndividu !== currentChargeDev.idIndividu);

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogContent>
                <FormControl>
                    <FormLabel id="radio-buttons-group">Sélectionner un remplaçant</FormLabel>
                    <RadioGroup aria-labelledby="radio-buttons-group" name="cd-selection"
                        onChange={e => setNewChargeDevId(e.target.value)}
                    >
                        {remplacants.map(cd =>
                            <FormControlLabel key= {cd.idIndividu} value={cd.idIndividu} control={<Radio />} label={cd.nom + ' ' + cd.prenom} />
                        )}
                    </RadioGroup>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Annuler</Button>
                {newChargeDevId && <Button onClick={handleClose}>Confirmer</Button>}
            </DialogActions>

        </Dialog>
    );
}