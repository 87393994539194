import React from 'react';
import _ from 'lodash';

import {
  clusterEntities,
  getDefaultStyle,
} from '../../service/regions_transac';
import './map.css';
import { mapStyle } from './mapStyle';
import { createRoot } from 'react-dom/client';
import Legend from '../Legend';

var L = window.L;

var greenIcon = new L.Icon({
  iconUrl: 'img/marker-icon-green.png',
  shadowUrl: 'img/marker-shadow.png',
  iconAnchor: [12, 41],
});

var greyIcon = new L.Icon({
  iconUrl: 'img/marker-icon-grey.png',
  shadowUrl: 'img/marker-shadow.png',
  iconAnchor: [12, 41],
});

var redIcon = new L.Icon({
  iconUrl: 'img/marker-icon-red.png',
  shadowUrl: 'img/marker-shadow.png',
  iconAnchor: [12, 41],
});

class MapSecteurDirectionVente extends React.PureComponent {
  constructor(props) {
    super(props);

    this.map = null;

    this.franceLayer = null;
    this.regionLayer = null;
    this.communeLayer = null;

    this.lastSelectedMarker = null;
    this.agenceMarkers = null;
    this.agenceClusters = null;

    this.defaultStyle = getDefaultStyle();
  }

  initFranceLayer(france) {
    this.franceLayer = L.geoJSON(france, {
      style: (feature) => {
        let s = this.defaultStyle[feature.properties.nom];
        return { weight: 0.5, color: this.defaultStyle.color, ...s };
      },
      onEachFeature: (featureR, layerR) => {
        let nomZone = featureR.properties.code;

        layerR.on('click', (eR) => {
          // click on Region
          this.props.handleSelectZone(featureR.properties);

          fetch(`./output/transac/dc/${nomZone}/contour-departements.json`)
            .then((res) => res.json())
            .then((deps) => {
              this.regionLayer = L.geoJSON(deps, {
                style: (feature) => {
                  let s = { weight: 1.5, color: this.defaultStyle.color };
                  return s;
                },
              })
                .addTo(this.map)
                .bringToBack();
            })
            .catch(console.error);

          this.map.fitBounds(layerR.getBounds());
          this.franceLayer.remove();
        });
      },
    }).addTo(this.map);
  }

  showEtablissements(etablissements) {
    this.agenceMarkers.clearLayers();
    this.agenceClusters.clearLayers();

    // ajouter un champ pour les établissements dans un cluster
    clusterEntities(etablissements);

    let clusters = {};
    for (let etablissement of etablissements) {
      if (etablissement.hasOwnProperty('cluster')) {
        if (!clusters[etablissement.cluster])
          clusters[etablissement.cluster] = [];

        clusters[etablissement.cluster].push(etablissement);
      } else
        L.marker([etablissement.latitude, etablissement.longitude], {
          etablissement,
        }).addTo(this.agenceMarkers);
    }

    for (let i in clusters) {
      let markers = L.markerClusterGroup({ showCoverageOnHover: false }).addTo(
        this.map
      );

      let cluster = clusters[i];
      cluster.forEach((member) => {
        let marker = L.marker([member.latitude, member.longitude], {
          etablissement: member,
        });
        markers.addLayer(marker);
        this.agenceClusters.addLayer(marker);
      });
    }

    this.agenceMarkers.on('click', (e) => this.onEtabMarkerClick(e));
    this.agenceClusters.on('click', (e) => this.onEtabMarkerClick(e));
  }

  fetchCommunesByDep(metier, codeDep, selectedEtabSecteurs, allSecteurEtab) {
    let nomZone = this.props.selectedZone.nom;
    let couleurMetier = this.props.theme.palette[metier]
      ? this.props.theme.palette[metier].dark
      : 'green';
    fetch(`./output/transac/dc/${nomZone}/c${codeDep}.json`)
      .then((res) => res.json())
      .then((json) => {
        L.geoJSON(json, {
          style: (feature) => {
            let s = { weight: 0.5, color: this.defaultStyle.color };

            let codeCommune = feature.properties.code;

            let etabAffecte = allSecteurEtab[codeCommune];

            if (
              Array.isArray(selectedEtabSecteurs) &&
              _.find(selectedEtabSecteurs, { code: codeCommune })
            )
              s.fillColor = couleurMetier;
            else if (
              etabAffecte &&
              etabAffecte.refSiFinance !==
                this.props.selectedEtablissement.refSiSinance
            )
              s.fillColor = 'red';
            else s.fill = false;

            return s;
          },
          onEachFeature: (featureC, layerC) => {
            let commune = featureC.properties;

            if (
              layerC.options.fillColor !== 'red' &&
              this.props.selectedEtablissement.secteurAffectable
            ) {
              layerC.on('click', (eC) => {
                // click on Commune

                if (layerC.options.fillColor === couleurMetier) {
                  // remove selected commune
                  layerC.setStyle({
                    fillColor: this.defaultStyle.color,
                    fill: false,
                  });
                  this.props.handleRemoveCommune(commune.code);
                } else {
                  // select commune
                  layerC.setStyle({ fillColor: couleurMetier, fill: true });
                  this.props.handleSelectCommune(commune);
                }
              });
            } else if (layerC.options.fillColor === 'red') {
              let etabAffecte = allSecteurEtab[commune.code];

              layerC.on('click', (e) => {
                this.props.handleOpenSnackbar({
                  severity: 'warning',
                  msg: (
                    <>
                      <strong>{commune.nom}</strong> affecté à{' '}
                      <strong>{etabAffecte.nomUsage}</strong>
                    </>
                  ),
                });
              });

              let markerEtabAffecte;
              this.agenceMarkers.eachLayer((l) => {
                if (
                  l.options.etablissement.refSiFinance ===
                  etabAffecte.refSiFinance
                ) {
                  markerEtabAffecte = l;
                  return;
                }
              });

              if (!markerEtabAffecte) {
                this.agenceClusters.eachLayer((l) => {
                  if (
                    l.options.etablissement.refSiFinance ===
                    etabAffecte.refSiFinance
                  ) {
                    markerEtabAffecte = l;
                    return;
                  }
                });
              }

              layerC.on('mouseover', (e) => {
                markerEtabAffecte.setIcon(redIcon);
              });

              layerC.on('mouseout', (e) => {
                markerEtabAffecte.setIcon(greyIcon);
              });
            }
          },
        }).addTo(this.communeLayer);
      })
      .catch(console.error);
  }

  onEtabMarkerClick(e) {
    // handle the last selected marker
    if (this.lastSelectedMarker) {
      this.lastSelectedMarker.setIcon(L.Marker.prototype.options.icon);
      this.lastSelectedMarker.setZIndexOffset(0);
    }

    // handle the clicked marker
    let clickedMarker = e.layer;
    clickedMarker.setIcon(greenIcon);
    clickedMarker.setZIndexOffset(10000);
    this.lastSelectedMarker = clickedMarker;

    let etablissement = e.sourceTarget.options.etablissement;
    this.props.handleSelectEtablissement(_.cloneDeep(etablissement));
  }

  enableAffectationMode(allSecteurEtab, metier) {
    const selectedEtablissement = this.props.selectedEtablissement;
    if (selectedEtablissement) {
      let etab = this.props.selectedEtablissement;
      let selectedEtabSecteurs = etab[metier];
      this.agenceMarkers.off('click');
      this.agenceMarkers.eachLayer((layer) => {
        if (layer.options.icon !== greenIcon) layer.setIcon(greyIcon);
      });

      this.agenceClusters.off('click');
      this.agenceClusters.eachLayer((layer) => {
        if (layer.options.icon !== greenIcon) layer.setIcon(greyIcon);
      });

      this.regionLayer.eachLayer((depLayer) => {
        let codeDep = depLayer.feature.properties.code;

        let firstAffectation =
          _.isEmpty(selectedEtabSecteurs) &&
          etab.codePostal.startsWith(codeDep);
        let secteurInDep = _.find(selectedEtabSecteurs, (c) =>
          c.code.startsWith(codeDep)
        );

        if (firstAffectation || secteurInDep) {
          depLayer.setStyle({ fill: false });
          this.fetchCommunesByDep(
            metier,
            codeDep,
            selectedEtabSecteurs,
            allSecteurEtab
          );
        } else {
          depLayer.on('click', (e) => {
            depLayer.off('click');
            depLayer.setStyle({ fill: false });
            this.fetchCommunesByDep(
              metier,
              codeDep,
              selectedEtabSecteurs,
              allSecteurEtab
            );
          });
        }
      });
    }
  }

  disableAffectationMode() {
    this.communeLayer.clearLayers();

    this.agenceMarkers.eachLayer((layer) =>
      layer.setIcon(L.Marker.prototype.options.icon)
    );
    this.agenceMarkers.on('click', (e) => this.onEtabMarkerClick(e));

    this.agenceClusters.eachLayer((layer) =>
      layer.setIcon(L.Marker.prototype.options.icon)
    );
    this.agenceClusters.on('click', (e) => this.onEtabMarkerClick(e));

    this.regionLayer.eachLayer((depLayer) => {
      depLayer.off('click');
      depLayer.setStyle({ fill: true });
    });
  }

  showRegion() {
    this.map.fitBounds(this.regionLayer.getBounds());
  }

  showFrance() {
    this.agenceMarkers.clearLayers();
    this.agenceClusters.clearLayers();
    this.communeLayer.clearLayers();

    this.lastSelectedMarker.remove();

    this.regionLayer.remove();
    this.franceLayer.addTo(this.map);
    this.map.fitBounds(this.franceLayer.getBounds());
  }

  componentDidMount() {
    if (this.map) return;
    let arr = [46.845164, 2.614746];

    this.map = L.map('map', {
      preferCanvas: true,
      attributionControl: false,
      zoom: 6,
      minZoom: 6,
      maxZoom: 15,
      center: arr,
    });

    fetch(`./output/transac/france_transac.json`)
      .then((res) => res.json())
      .then((france) => this.initFranceLayer(france))
      .catch(console.error);

    this.map.zoomControl.setPosition('topright');

    L.maplibreGL({ style: mapStyle(this.props.osmUrls) }).addTo(this.map);

    this.agenceMarkers = L.featureGroup().addTo(this.map);
    this.agenceClusters = L.featureGroup();
    this.communeLayer = L.featureGroup().addTo(this.map);

    L.control
      .legend({ position: 'bottomright', id: 'legend-container' })
      .addTo(this.map);
    const legendRoot = createRoot(document.getElementById('legend-container'));
    legendRoot.render(<Legend theme={this.props.theme} isDVLegend={true} />);
  }

  render() {
    return <div id='map' className='map'></div>;
  }
}

export default MapSecteurDirectionVente;
