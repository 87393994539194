import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import OktaSignInWidget from './OktaSignInWidget';
import { useOktaAuth } from '@okta/okta-react';
import { AppConfigContext } from './context';
import BackendApiService from './service/BackendApiService';

const Login = () => {

  const appConfig = useContext(AppConfigContext);
  const { oktaAuth, authState } = useOktaAuth();

  const onSuccess = (tokens) => {
    const service = new BackendApiService(appConfig.app.backendApiUrl, tokens);

    service.fetchUserInfo()
    .then(res => {
      if(!res.ok)
        throw new Error(res.status);

      return res.json();
    })
    .then(data => {

      tokens.accessToken.profil = data;

      oktaAuth.tokenManager.on('renewed', function (key, newToken, oldToken) {
        if(key === 'accessToken') {
          newToken.profil = data;
          oktaAuth.tokenManager.add(key, newToken);
        }
      });

    })
    .catch(err => console.error(err))
    .finally(() => oktaAuth.handleLoginRedirect(tokens));

  };

  const onError = (err) => {
    console.error('Sign in error:', err);
  };

  if (!authState) {
    return <div>Loading ... </div>;
  }

  return authState.isAuthenticated ?
    <Redirect to={{ pathname: '/' }}/> :
    <OktaSignInWidget onSuccess={onSuccess} onError={onError}/>;
};

export default Login;