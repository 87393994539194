export const buildListZoneDcDv = (dataNotFiltred) =>  dataNotFiltred.reduce((acc, current) => {
    const existingZone = acc.find(item => item.zoneDc === current.zoneDc && item.zoneDcId === current.zoneDcId);
    if (existingZone) {
        existingZone.zones.push({
            zoneDv: current.zoneDv,
            refCabinet: current.refCabinet
        });
    } else {
        acc.push({
            zoneDc: current.zoneDc,
            zoneDcId: current.zoneDcId,
            zones: [{
                zoneDv: current.zoneDv,
                refCabinet: current.refCabinet
            }]
        });
    }
    return acc;
}, []);