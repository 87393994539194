
import React from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';

function TopBar(props) {

    const { authState } = useOktaAuth();

    if (!authState || !authState.isAuthenticated) return null;

    return <AppBar
        position="fixed"
        sx={{ width: `calc(100% - ${props.drawerWidth}px)`, ml: `${props.drawerWidth}px` }} >
        <Toolbar>
            <Typography variant="h6" noWrap component="div">
                Sectorisation
            </Typography>
        </Toolbar>
    </AppBar>;
}

export default TopBar;