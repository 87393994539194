import {
  AccordionSummary,
  AccordionDetails,
  Accordion,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const AccordionParZone = (props) => {
  const {
    zoneDc,
    zones,
    selectedZones,
    handleCheckDv,
  } = props;  

  const handleChange = (event, zoneDvData) => {
    handleCheckDv(event.target.checked, zoneDc, zoneDvData);
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Typography variant='h6'>{zoneDc.zoneDc}</Typography>
      </AccordionSummary>

      <AccordionDetails>
        <FormGroup>
          {zones.map((zone) => (
              <FormControlLabel
                key={zone.zoneDv}
                label={zone.zoneDv}
                control={
                  <Checkbox
                    checked={selectedZones?.includes(zone.zoneDv)}
                    onChange={(e) => handleChange(e, zone)}
                  />
                }
              />
          ))}
        </FormGroup>
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionParZone
