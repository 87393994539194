import React, { useContext, useEffect, useRef } from 'react';
import OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import { AppConfigContext } from './context';

const OktaSignInWidget = ({ onSuccess, onError }) => {

  const appConfig = useContext(AppConfigContext);

  const widgetRef = useRef();

  useEffect(() => {

    if (!widgetRef.current) {
      return false;
    }

    const widget = new OktaSignIn(appConfig.widget);
    
    widget.showSignInToGetTokens({
      el: widgetRef.current,
    })
    .then(onSuccess)
    .catch(onError);

    widget.on('afterError', function (context, error) {
      onError(error);
    });

    return () => widget.remove();

  }, [onSuccess, onError, appConfig]);

  return (<div ref={widgetRef} />);
};

export default OktaSignInWidget;