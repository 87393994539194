import CloseIcon from '@mui/icons-material/Close';
import { Alert, IconButton, Snackbar } from "@mui/material";


function SnackbarFeedback(props) {

    const {openSnackbar, handleCloseSnackbar, feedback} = props;

    return <Snackbar open={openSnackbar}
        autoHideDuration={4000} onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        action={<IconButton size="small" aria-label="close" onClick={handleCloseSnackbar}><CloseIcon fontSize="small" /></IconButton>}
    >
        {feedback && <Alert severity={feedback.severity} onClose={handleCloseSnackbar}>{feedback.msg}</Alert>}
    </Snackbar>;
}

export default SnackbarFeedback;