
import React, { useState } from 'react';

import _ from 'lodash';

import { Grid, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material';

import { region_idf, region_nord_est, region_grand_ouest, region_sud_est } from '../service/regions_foncia';

import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import AccordionParRegion from '../components/AccordionParRegion';
import ShowLoading from '../components/ShowLoading';
import AccordionParZone from '../components/AccordionParZone';

import { ADMIN, DIRECTEUR_COMMERCIAL_TRANSACTION } from '../Constantes';

const all_regions = [region_idf, region_nord_est, region_sud_est, region_grand_ouest];

function UserEdit(props) {

    const { exitEditMode, service, selectedUser, handleOpenSnackbar, zonesDcDv } = props;
    let userToEdit;
    if (selectedUser)
        userToEdit = { ...selectedUser, access: selectedUser.access?.map(e => ({ refRegion: e.refRegion, refCabinet: e.refCabinet })) };
    else
        userToEdit = { refIndividu: '', profil: 'admin', access: [] };

    const [editedUser, setEditedUser] = useState(userToEdit);
    const [loading, setLoading] = useState(false);
    

    const handleChangeField = (fieldName, value) => {
        let newUser = {};
        newUser[fieldName] = value;
        if(fieldName === 'profil') {
            setEditedUser({ ...editedUser, ...newUser, access: []  });
        } else {
            setEditedUser({ ...editedUser, ...newUser });
        }
    }

    const handleCheckSociete = (checked, nomRegion, refCabinet) => {
        if (checked) {
            setEditedUser({ ...editedUser, access: [...editedUser.access, { refRegion: nomRegion, refCabinet }] });
        } else {
            _.remove(editedUser.access, function (e) { return e.refCabinet === refCabinet; });
            setEditedUser({ ...editedUser });
        }

    }

    const handleCheckDv = (checked, ZoneDcDATA, zoneDvData) => {
        if (checked) {
            setEditedUser({ ...editedUser, access: [...editedUser.access, { ...zoneDvData, zoneDcId: ZoneDcDATA.zoneDcId }] });
        } else {
            _.remove(editedUser.access, function (e) { return e.zoneDv === zoneDvData.zoneDv; });
            setEditedUser({ ...editedUser });
        }
    }

    const saveEditedUser = function () {

        setLoading(true);

        service.saveUser(editedUser)
            .then(res => {
                if (!res.ok)
                    throw new Error(res.status);
                else {
                    handleOpenSnackbar({ severity: 'success', msg: 'Sauvegarde OK' });
                    exitEditMode();
                }
            })
            .catch(error => {
                handleOpenSnackbar({ severity: 'error', msg: 'Erreur survenue: ' + error.message });
            })
            .finally(() => setLoading(false));
    }

    const showCabinetsByZone = zonesDcDv && zonesDcDv.length > 0 && [DIRECTEUR_COMMERCIAL_TRANSACTION, ADMIN].includes(editedUser?.profil)

    return <>
        <Grid container direction="row" justifyContent="space-between" alignItems="center" padding={"20px 0"}>
            <Grid item>
                <Typography variant="h5">Créer ou Modifier un utilisateur</Typography>
            </Grid>
            <Grid item>
                <ShowLoading loading={loading}></ShowLoading>
                {!loading &&
                    <>
                        <Tooltip title="Sauvegarder">
                            <IconButton aria-label="create" color="primary" onClick={saveEditedUser}><SaveIcon /></IconButton>
                        </Tooltip>
                        <Tooltip title="Annuler">
                            <IconButton aria-label="cancle" onClick={exitEditMode}><CancelIcon /></IconButton>
                        </Tooltip>
                    </>
                }

            </Grid>
        </Grid>


        <Grid container spacing={3}>
            <Grid item xs={3}>
                <InputLabel id="txt-ref-individu">Ref individu</InputLabel>
                <TextField fullWidth labelid="txt-ref-individu" value={editedUser.refIndividu} onChange={(e) => handleChangeField('refIndividu', e.target.value)} />
            </Grid>

            <Grid item xs={3}>
                <InputLabel id="select-profil">Profil</InputLabel>
                <Select fullWidth labelid='select-profil' value={editedUser.profil} onChange={(e) => handleChangeField('profil', e.target.value)}>
                    <MenuItem value={"admin"}>Admin</MenuItem>
                    <MenuItem value={"directeur_commercial"}>Directeur Commercial</MenuItem>
                    <MenuItem value={"gestionnaire_region"}>Gestionnaire Région</MenuItem>
                    <MenuItem value={DIRECTEUR_COMMERCIAL_TRANSACTION}>Directeur commercial transaction</MenuItem>
                </Select>
            </Grid>

            {editedUser?.profil !== DIRECTEUR_COMMERCIAL_TRANSACTION &&  
                <Grid item xs={12}>
                    <InputLabel id="select-profil">Accès aux cabinets par région</InputLabel>

                    {all_regions.map(region =>
                        <AccordionParRegion 
                            key={region.code}
                            nomRegion={region}
                            service={service}
                            selectedSocietes={editedUser.access?.filter(e => e.refRegion === region).map(e => e.refCabinet)}
                            handleCheckSociete={handleCheckSociete}
                            handleOpenSnackbar={handleOpenSnackbar}
                        />
                    )}

                </Grid>
            }

           {showCabinetsByZone &&
                <Grid item xs={12} spacing={5} >
                    <InputLabel id="select-profil">Accès aux cabinets par zone</InputLabel>

                    {zonesDcDv.map((zoneDc,index) =>
                        <AccordionParZone 
                            key={index}
                            zoneDc={zoneDc}
                            zones={zoneDc.zones}
                            selectedZones={editedUser.access?.filter(e => e.zoneDcId === zoneDc.zoneDcId).map(e => e.zoneDv)}
                            handleCheckDv={handleCheckDv}
                        />
                    )}

                </Grid> 
            }         
        </Grid>

    </>;
}

export default UserEdit;