import React from 'react';
import { createRoot } from 'react-dom/client';

import './map.css';
import { mapStyle } from './mapStyle';
import _ from 'lodash';

import { clusterEntities, getDefaultStyle } from '../../service/regions_foncia';
import Legend from '../Legend';


var L = window.L;

var greenIcon = new L.Icon({
    iconUrl: 'img/marker-icon-green.png',
    shadowUrl: 'img/marker-shadow.png',
    iconAnchor: [12, 41]
});

var greyIcon = new L.Icon({
    iconUrl: 'img/marker-icon-grey.png',
    shadowUrl: 'img/marker-shadow.png',
    iconAnchor: [12, 41]
});

class MapSecteurChargeDev extends React.PureComponent {
    constructor(props) {
        super(props);

        this.map = null;

        this.franceLayer = null;
        this.regionLayer = null;
        this.communeLayer = null;

        this.lastedSelectedMarker = null;
        this.societeMarkers = null;

        this.defaultStyle = getDefaultStyle();

    }

    initFranceLayer(france) {

        this.franceLayer = L.geoJSON(france, {
            style: (feature) => {
                let s = this.defaultStyle[feature.properties.nom];
                return { weight: 0.5, color: this.defaultStyle.color, ...s };
            },
            onEachFeature: (featureR, layerR) => {

                let nomRegion = featureR.properties.nom;

                layerR.on('click', eR => {
                    // click on Region

                    this.props.handleSelectRegion(featureR.properties)

                    fetch(`./output/${nomRegion}/contour-departements.json`)
                        .then(res => res.json())
                        .then(deps => {

                            this.regionLayer = L.geoJSON(deps, {
                                style: (feature) => {
                                    let s = { weight: 1.5, color: this.defaultStyle.color };
                                    return s;
                                }
                            }).addTo(this.map).bringToBack();
                        })
                        .catch(console.error);

                    this.map.fitBounds(layerR.getBounds());
                    this.franceLayer.remove();

                });
            }
        }).addTo(this.map);
    }

    showSocietes(societes) {

        this.societeMarkers.clearLayers();
        this.societeClusters.clearLayers();

        clusterEntities(societes);

        let clusters = {};
        for (let societe of societes) {

            if(societe.hasOwnProperty('cluster')) {
                if(!clusters[societe.cluster])
                    clusters[societe.cluster] = [];

                clusters[societe.cluster].push(societe);
            }
            else
                L.marker([societe.latitude, societe.longitude], { societe }).addTo(this.societeMarkers);

        }

        for(let i in clusters) {
            let markers = L.markerClusterGroup({ showCoverageOnHover: false }).addTo(this.map);

            let cluster = clusters[i];
            cluster.forEach(member => {
                let marker = L.marker([member.latitude, member.longitude], { societe: member });
                markers.addLayer(marker);
                this.societeClusters.addLayer(marker);
            });
        }

        this.societeMarkers.on('click', e => this.onSocMarkerClick(e));
        this.societeClusters.on('click', e => this.onSocMarkerClick(e));

    }

    onSocMarkerClick(e) {

        // handle the last selected marker
        if (this.lastSelectedMarker) {
            this.lastSelectedMarker.setIcon(L.Marker.prototype.options.icon);
            this.lastSelectedMarker.setZIndexOffset(0);
        }

        // handle the clicked marker
        let clickedMarker = e.layer;
        clickedMarker.setIcon(greenIcon);
        clickedMarker.setZIndexOffset(10000);
        this.lastSelectedMarker = clickedMarker;
        
        let societe = e.sourceTarget.options.societe;

        // this.selectedSocMarker.setLatLng([societe.latitude, societe.longitude]).addTo(this.map);
        
        this.props.handleSelectSociete(societe);
    }

    fetchCommunesByDep(metier, codeDep, currentAffectations, allSecteursCd) {

        let nomRegion = this.props.selectedRegion.nom;
        let couleurMetier = this.props.theme.palette[metier] ? this.props.theme.palette[metier].dark : 'green';

        fetch(`./output/${nomRegion}/c${codeDep}.json`)
            .then(res => res.json())
            .then(json => {

                L.geoJSON(json, {

                    style: (feature) => {
                        let s = { weight: 0.5, color: this.defaultStyle.color };

                        let codeCommune = feature.properties.code;

                        if (_.has(allSecteursCd, codeCommune)) {
                            let cdAffecte = allSecteursCd[codeCommune];

                            if (_.find(currentAffectations, { code: codeCommune }))
                                s.fillColor = couleurMetier;
                            else if (cdAffecte && cdAffecte.idIndividu !== this.props.selectedChargeDev.idIndividu)
                                s.fillColor = 'red';
                            else
                                s.fillColor = 'white';
                        } else {
                            s.fillColor = 'black'
                        }

                        return s;
                    },
                    onEachFeature: (featureC, layerC) => {

                        let commune = featureC.properties;

                        if (!layerC.options.fill || layerC.options.fillColor === 'black') {
                            layerC.on('click', e => {
                                this.props.handleOpenSnackbar({ severity: 'warning', msg: <><strong>{commune.nom}</strong> n'est pas affecté à cette société</> });
                            });
                        } else if (layerC.options.fillColor === 'red') {
                            let cdAffecte = allSecteursCd[commune.code];

                            layerC.on('click', e => {
                                this.props.handleOpenSnackbar({ severity: 'warning', msg: <><strong>{commune.nom}</strong> affecté à <strong>{cdAffecte.prenom} {cdAffecte.nom}</strong></> });
                            });
                        } else {

                            if(this.props.selectedSociete.cdAffectable){

                                layerC.on('click', (eC) => {
                                    // click on Commune
    
                                    if (layerC.options.fillColor === couleurMetier) {
                                        // remove selected commune
                                        layerC.setStyle({ fillColor: 'white'});
                                        this.props.handleRemoveCommune(commune.code);
                                    } else {
                                        // select commune
                                        layerC.setStyle({ fillColor: couleurMetier });
                                        this.props.handleSelectCommune(commune);
                                    }
    
                                });
                            } else {

                                layerC.on('click', (eC) => {
                                    // click on Commune
                                    this.props.handleOpenSnackbar({ severity: 'warning', msg: <>Cette société n'est pas dans votre périmètre d'affectation</> });
                                });

                            }

                        }

                    }
                }).addTo(this.communeLayer);

            })
            .catch(console.error);

    }

    enableAffectationMode(currentAffectations, metier, allSecteursCd) {

        let soc = this.props.selectedSociete;

        this.societeMarkers.off('click');
        this.societeMarkers.eachLayer(layer => {
            if(layer.options.icon !== greenIcon)
                layer.setIcon(greyIcon);
        });

        this.societeClusters.off('click');
        this.societeClusters.eachLayer(layer => {
            if(layer.options.icon !== greenIcon)
                layer.setIcon(greyIcon);
        });

        this.regionLayer.eachLayer(depLayer => {
            let codeDep = depLayer.feature.properties.code;

            // TODO: find codeDep by codePostal
            let firstAffectation = _.isEmpty(currentAffectations) && soc.codePostal.startsWith(codeDep);
            let cdHasSecteurInDep = _.find(currentAffectations, c => c.code.startsWith(codeDep));

            if (firstAffectation || cdHasSecteurInDep) {
                depLayer.setStyle({ fill: false });
                this.fetchCommunesByDep(metier, codeDep, currentAffectations, allSecteursCd);
            } else {
                // click on Departement
                depLayer.on('click', e => {
                    depLayer.off('click');
                    depLayer.setStyle({ fillColor: this.defaultStyle.color });
                    this.fetchCommunesByDep(metier, codeDep, currentAffectations, allSecteursCd);
                });
            }

        });
    }

    disableAffectationMode() {

        this.communeLayer.clearLayers();

        this.societeMarkers.eachLayer(layer => layer.setIcon(L.Marker.prototype.options.icon));
        this.societeMarkers.on('click', e => this.onSocMarkerClick(e));

        this.societeClusters.eachLayer(layer => layer.setIcon(L.Marker.prototype.options.icon));
        this.societeClusters.on('click', e => this.onSocMarkerClick(e));

        this.regionLayer.eachLayer(depLayer => {
            depLayer.off('click');
            depLayer.setStyle({ fill: true });
        });

    }

    showRegion() {
        this.map.fitBounds(this.regionLayer.getBounds());
    }

    showFrance() {

        this.societeMarkers.clearLayers();
        this.societeClusters.clearLayers();
        this.communeLayer.clearLayers();

        // this.selectedSocMarker.remove();

        this.regionLayer.remove();
        this.franceLayer.addTo(this.map);
        this.map.fitBounds(this.franceLayer.getBounds());
    }

    componentDidMount() {

        // console.log('this.map', this.map);
        if (this.map) return;

        console.log('init map...');
        let arr = [46.845164, 2.614746];

        this.map = L.map("map", {
            preferCanvas: true,
            attributionControl: false,
            zoom: 6,
            minZoom: 6,
            maxZoom: 15,
            center: arr
        });

        fetch(`./output/france.json`)
            .then(res => res.json())
            .then(france => this.initFranceLayer(france))
            .catch(console.error);

        this.map.zoomControl.setPosition('topright');

        L.maplibreGL({ style: mapStyle(this.props.osmUrls) }).addTo(this.map);

        // this.selectedSocMarker = L.marker(arr, { icon: greenIcon, zIndexOffset: 1000 });

        this.societeMarkers = L.featureGroup().addTo(this.map);
        this.societeClusters = L.featureGroup();
        this.communeLayer = L.featureGroup().addTo(this.map);

        L.control.legend({ position: 'bottomright', id: 'legend-container' }).addTo(this.map);
        const legendRoot = createRoot(document.getElementById('legend-container')); 
        legendRoot.render(<Legend theme={this.props.theme}/>);
    }

    render() {
        return <div id="map" className="map"></div>;
    }
}

export default MapSecteurChargeDev;