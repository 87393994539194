import { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import ShowLoading from "./ShowLoading";


function ConfirmDeleteUserDialog(props) {

  const { service, idUserToDelete, onCloseDialog, handleOpenSnackbar } = props;

  const [loading, setLoading] = useState(false);

  const handleDelete = function () {

    setLoading(true);

    service.deleteUser(idUserToDelete)
      .then(res => {
        if (!res.ok)
          throw new Error(res.status);

        handleOpenSnackbar({ severity: 'success', msg: 'Suppression OK' });
      })
      .catch(error => {
        handleOpenSnackbar({ severity: 'error', msg: 'Erreur survenue: ' + error.message });
      })
      .finally(() => {
        setLoading(false);
        onCloseDialog(null, true);
      });
  }

  return <Dialog
    open={idUserToDelete !== ''}
    onClose={e => onCloseDialog(e, false)}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{"Confirmation de la suppression"}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Souhaitez-vous vraiment supprimer l'utilisateur '{idUserToDelete}'?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <ShowLoading loading={loading}></ShowLoading>
      {!loading && <>
        <Button onClick={handleDelete} color="primary">Oui</Button>
        <Button onClick={e => onCloseDialog(e, false)} color="primary" autoFocus>Non</Button>
      </>}
    </DialogActions>
  </Dialog>
}

export default ConfirmDeleteUserDialog;