
import _ from 'lodash';

// logo foncia colors
// #dab6d4 #eb690b
// #7780b8 #00925b #f8b322
// #003f7d #0091b4 #39b6b8


export const region_idf = 'Ile-de-France';
export const region_nord_est = 'Nord-Est';
export const region_sud_est = 'Sud-Est';
export const region_grand_ouest = 'Grand-Ouest';


export function buildSecteurAffecteEtab(etabs, metier) {
    let secteurAffecte = {};
    etabs.forEach(e => {
        if (Array.isArray(e[metier]))
            e[metier].forEach(commune => secteurAffecte[commune.code] = e);
    });
    return secteurAffecte;
}


export function buildAllSecteursCd(societe, metier, collabs) {
    let allSecteursCd = {};
    societe.agences.forEach(etab => {
        if (Array.isArray(etab[metier]))
            etab[metier].forEach(affectation => allSecteursCd[affectation.code] = findCollabsById(collabs, affectation.refIndividu));
    });
    return allSecteursCd;
}

export function getCurrentAffectationsDuCd(societe, metier, cd) {
    let affectations = [];
    societe.agences.forEach(etab => {
        if (Array.isArray(etab[metier]))
        etab[metier].forEach(affectation => {
            if(affectation.refIndividu && affectation.refIndividu === cd.idIndividu)
            affectations.push(affectation);
        });
    });
    return affectations;
}

export function getDefaultStyle() {

    let defaultStyle = {
        'color': 'grey',
        [region_idf]: {'fillColor': '#FFF200', 'fillOpacity': 0.3},
        [region_nord_est]: {'fillColor': '#FF8484', 'fillOpacity': 0.3},
        [region_sud_est]: {'fillColor': '#c8bfe7', 'fillOpacity': 0.6},
        [region_grand_ouest]: {'fillColor': '#22B14C', 'fillOpacity': 0.3},
    };

    return defaultStyle;
}

function findCollabsById(collabs, idCollab) {
    return _.find(collabs, {idIndividu: idCollab});
}

function removeAccents(str) {
    const accents = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ';
    const nonAccents = 'AAAAAAaaaaaaOOOOOOooooooEEEEeeeeCcIIIIiiiiUUUUuuuuyNn';

    const updatedStr = str.split('').map((char, index) => {
        const accentIndex = accents.indexOf(char);
        return accentIndex !== -1 ? nonAccents[accentIndex] : char;
    }).join('');

    return updatedStr;
}

function normalizeAddress(address) {
    if(!address)
        return address;

    // Convert to uppercase
    address = address.toUpperCase();

    // Remove accents
    address = removeAccents(address);

    // Trim whitespace
    address = address.trim();

    // Remove unnecessary punctuation
    address = address.replace(/\./g, "");

    // Standardize common abbreviations
    address = address.replace(/\bST\b/g, "SAINT");
    address = address.replace(/\bSTE\b/g, "SAINTE");
    address = address.replace(/\bAV\.?\b/g, "AVENUE");
    address = address.replace(/\bAVE\.?\b/g, "AVENUE");
    address = address.replace(/\bBD\.?\b/g, "BOULEVARD");
    address = address.replace(/\bRUE\b/g, "RUE");
    address = address.replace(/\bPL\.?\b/g, "PLACE");
    address = address.replace(/\bIMP\.?\b/g, "IMPASSE");
    
    return address;
}

// Haversine formula to calculate the distance between two entities specified in latitude and longitude
function haversineDistance(entity1, entity2) {
    const R = 6371e3; // Earth radius in meters
    const lat1Rad = Math.PI * entity1.latitude / 180;
    const lat2Rad = Math.PI * entity2.latitude / 180;
    const deltaLat = lat2Rad - lat1Rad;
    const deltaLon = Math.PI * (entity2.longitude - entity1.longitude) / 180;

    const a = Math.sin(deltaLat/2) * Math.sin(deltaLat/2) +
              Math.cos(lat1Rad) * Math.cos(lat2Rad) *
              Math.sin(deltaLon/2) * Math.sin(deltaLon/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

    return R * c; // Distance in meters
}


// ajouter le champ 'cluster' aux entités (établissements ou sociétés) qui ont la même adresse ou sont trop proches entre elles
export function clusterEntities(entities) {
    let threshold = 150; // in meters

    for(let i = 0; i < entities.length; i++) {
        let entity = entities[i];

        if(entity.cluster) 
            continue;
        
        for(let j = i+1; j < entities.length; j++) {
            let nextEntity = entities[j];

            if(entity.codePostal === nextEntity.codePostal) {

                let memeAdresse = normalizeAddress(entity.adresseLigne1) === normalizeAddress(nextEntity.adresseLigne1)
                let distanceTooClose = haversineDistance(entity, nextEntity) <= threshold;

                if(memeAdresse || distanceTooClose) {
                    entity.cluster = i;
                    nextEntity.cluster = i;
                }
            } 
        }
    }
}



