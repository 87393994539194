import { CircularProgress, Typography } from "@mui/material";


export default function ShowLoading({loading}) {

    if(loading)
        return <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>Chargement... <CircularProgress size={20} /></Typography>;
    else
        return null;

}