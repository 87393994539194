import React, { useContext, useEffect, useState } from 'react';

import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExportContainer,
    GridCsvExportMenuItem
} from '@mui/x-data-grid';
import BackendApiService from '../service/BackendApiService';
import { useOktaAuth } from '@okta/okta-react';
import { AppConfigContext } from '../context';
import SnackbarFeedback from '../components/SnackbarFeedback';
import { useMemo } from 'react';

const csvOptions = { delimiter: ';', utf8WithBom: true };

function CustomExportButton(props) {
    return (
        <GridToolbarExportContainer {...props}>
            <GridCsvExportMenuItem options={csvOptions} />
        </GridToolbarExportContainer>
    );
}

function CustomToolbar(props) {
    return (
        <GridToolbarContainer {...props}>
            <CustomExportButton />
        </GridToolbarContainer>
    );
}

const columns = [
    { field: 'nomCabinet', headerName: 'Nom cabinet', width: 250 },
    { field: 'nomAgence', headerName: 'Nom agence', width: 250 },
    { field: 'refMetier', headerName: 'Ref metier', width: 80 },
    { field: 'zoneCode', headerName: 'Zone code', width: 120 },
    { field: 'zoneNom', headerName: 'Zone nom', width: 200 },
    { field: 'zoneCodesPostaux', headerName: 'Zone codes postaux', width: 150 },
    { field: 'refIndividu', headerName: 'Ref individu', width: 120 },
    { field: 'nomCollaborateur', headerName: 'Nom collaborateur', width: 200 },
];

export default function CustomExport(props) {
    const appConfig = useContext(AppConfigContext);
    const { authState } = useOktaAuth();
    const [affectations, setAffectations] = useState([]);
    const [feedback, setFeedback] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [loading, setLoading] = useState(false);

    const service = useMemo(() => new BackendApiService(appConfig.app.backendApiUrl, authState), [appConfig.app.backendApiUrl, authState]);

    const handleOpenSnackbar = function (feedback) {
        setFeedback(feedback);
        setOpenSnackbar(true);
    }
    const handleCloseSnackbar = function () {
        setOpenSnackbar(false);
    }

    useEffect(() => {
    
        const fetchAffectations = async () => {
            setLoading(true);
            let response;
            try {
                let page = 1;
                let size = 20000;
                let affectationsResult = []
                do {
                    response = await service.fetchAffectations(page, size);
                    const affectationsPaginat  = await response.json();
                    affectationsResult = [...affectationsResult, ...affectationsPaginat]
                    page++;
                } while(response.status === 200)

                setAffectations(affectationsResult);
            } catch (error) {
                console.log(error);
                if(response.status === 401){
                    handleOpenSnackbar({ severity: 'error', msg: 'Opération non autorisée' });
                } else if(response.status === 403){
                    handleOpenSnackbar({ severity: 'error', msg: 'Vous n\'avez pas le droit pour effectuer cette opération' });
                } else if(response.status === 404){
                    handleOpenSnackbar({ severity: 'error', msg: 'Aucun collaborateur n\'est rattaché à cette agence' });
                } else {
                    handleOpenSnackbar({ severity: 'error', msg: 'Erreur survenue: ' + error.message });
                }
            } finally {
                setLoading(false);
            }
        }
        fetchAffectations();
    }, [service]);
    

    return (
        <div>
            <DataGrid autoHeight
                rows={affectations}
                columns={columns}
                loading={loading}
                initialState={{
                    pagination: {
                    paginationModel: { page: 0, pageSize: 10, },
                    labelRowsPerPage: 'Ligne par page'
                    },
                }}
                pageSizeOptions={[ 10, 25, 50, 100]}
                slotProps={{ pagination: { labelRowsPerPage: 'Ligne par page', labelDisplayedRows: ({ from, to, count }) =>`${from} - ${to} / ${count === -1 ? '' : count}` } }}
                localeText={{ 
                    toolbarExportCSV: "Télécharger en format CSV",  
                    }
                }
                slots={{ toolbar: CustomToolbar }}
                getRowId={(row) =>  row.zoneNom + row.zoneCodesPostaux + row.zoneNom + row.refIndividu}
            />  
            <SnackbarFeedback
                openSnackbar={openSnackbar}
                handleCloseSnackbar={handleCloseSnackbar}
                feedback={feedback}
            />
        </div>
    );
}
