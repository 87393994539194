import React, { useState, useEffect } from "react";

const AppConfigContext = React.createContext();

/*
const appConfig = {
  oidc: {
    issuer: appConfig.OKTA_ISSUER,
    clientId: appConfig.OKTA_CLIENT_ID,
    // clientId: '0oa508ujpyZwOYe3r0x7',// sectorisation manuel
    // clientId: '0oa506w16p4NfXQud0x7',// sectorisation int
    // clientId: '0oa4avnkikki0z7gW0x7',//datafalcon
    // clientId: '0oa102o7e7VgFZMjj0x7',//apple-helper dev
    // clientId: '0oa4tgkeng7mPg98D0x7',//apple-helper int
    scopes: ['openid', 'profile', 'email'],
    redirectUri: `${window.location.origin}/login/callback`
  },
  widget: {
    issuer: appConfig.OKTA_ISSUER,
    clientId: appConfig.OKTA_CLIENT_ID,
    clientId: '0oa508ujpyZwOYe3r0x7',// sectorisation manuel
    // clientId: '0oa506w16p4NfXQud0x7',// sectorisation int
    // clientId: '0oa4avnkikki0z7gW0x7',//datafalcon
    // clientId: '0oa102o7e7VgFZMjj0x7',//apple-helper dev
    // clientId: '0oa4tgkeng7mPg98D0x7',//apple-helper int
    logo: 'img/logo.svg',
    redirectUri: `${window.location.origin}/login/callback`,
    scopes: ['openid', 'profile', 'email']
  }
};
*/


function AppConfigProvider(props) {
  const [appConfig, setAppConfig] = useState(null);

  useEffect(() => {
    fetch("/conf.json", { headers: { Accept: "application/json" } })
      .then(res => res.json())
      .then(json => {
        let config = {
          oidc: {
            issuer: json.OKTA_ISSUER,
            clientId: json.OKTA_CLIENT_ID,
            scopes: ['openid', 'profile', 'email'],
            redirectUri: `${window.location.origin}/login/callback`
          },
          widget: {
            issuer: json.OKTA_ISSUER,
            clientId: json.OKTA_CLIENT_ID,
            logo: 'img/logo.svg',
            redirectUri: `${window.location.origin}/login/callback`,
            scopes: ['openid', 'profile', 'email']
          },
          app: {
            backendApiUrl : json.BACKEND_API_URL,
            osmUrls: json.OSM_URLS ? json.OSM_URLS.split(',') : []
          }
        };

        setAppConfig(config);

      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <AppConfigContext.Provider value={appConfig}>
      {appConfig ? props.children: null}
    </AppConfigContext.Provider>
  );
}

export { AppConfigProvider, AppConfigContext };
