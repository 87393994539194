
import React, { useState } from 'react';


import { Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ShowLoading from './ShowLoading';

function AccordionParRegion(props) {

    const { nomRegion, service, selectedSocietes, handleCheckSociete, handleOpenSnackbar } = props;

    const [societes, setSocietes] = useState([]);

    const [loading, setLoading] = useState(false);


    const showSocietesByRegion = function (nomRegion, expanded) {

        if (expanded && societes.length > 0)
            return;

        setLoading(true);

        let errorStatus;
        service.fetchSocietes(nomRegion)
            .then(res => {
                if (!res.ok) {
                    errorStatus = res.status;
                    return res.text();
                }
                return res.json();
            })
            .then(data => {
                if (errorStatus)
                    throw new Error(errorStatus + ' ' + data);

                setSocietes(data);
            })
            .catch(error => {
                handleOpenSnackbar({ severity: 'error', msg: 'Erreur survenue: ' + error.message });
            })
            .finally(() => setLoading(false));
    }

    const handleChange = function (event, refCabinet) {
        handleCheckSociete(event.target.checked, nomRegion, refCabinet);
    }

    return <Accordion onChange={(event, expanded) => showSocietesByRegion(nomRegion, expanded)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography variant='h6'>{nomRegion}</Typography>
        </AccordionSummary>

        <AccordionDetails>

            <FormGroup>
                <ShowLoading loading={loading}></ShowLoading>

                {!loading && societes.map(soc =>
                    <FormControlLabel key={soc.refSiFinance}
                        label={`${soc.refSiFinance} - ${soc.denominationSociale}`}
                        control={<Checkbox checked={selectedSocietes?.includes(soc.refSiFinance)} onChange={e => handleChange(e, soc.refSiFinance)} />}
                    />
                )}
            </FormGroup>

        </AccordionDetails>
    </Accordion>

}

export default AccordionParRegion;