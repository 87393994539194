
import { Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';


import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ConfirmDeleteUserDialog from '../components/ConfirmDeleteUserDialog';
import ShowLoading from '../components/ShowLoading';
import { region_idf, region_nord_est, region_grand_ouest, region_sud_est } from '../service/regions_foncia';

const empty_user = {
    refIndividu: '',
    profil: 'directeur_commercial',
    access: []
};


function UserList(props) {

    const { handleClickEditUser, handleOpenSnackbar, service, zonesDc } = props;

    const [users, setUsers] = useState([]);

    const [idUserToDelete, setIdUserToDelete] = useState('');

    const [loading, setLoading] = useState(false);

    const fetchUserList = useCallback(() => {
        setLoading(true);

        service.fetchUsers()
            .then(res => {
                if (!res.ok)
                    throw new Error(res.status);

                return res.json();
            })
            .then(data => setUsers(data))
            .catch(error => {
                handleOpenSnackbar({ severity: 'error', msg: 'Erreur survenue: ' + error.message });
            })
            .finally(() => setLoading(false));
    }, [handleOpenSnackbar, service]);

    useEffect(() => {
        fetchUserList();

    }, [fetchUserList]);

    const onCloseDialog = function (event, deleted) {
        setIdUserToDelete('');
        if(deleted) 
            fetchUserList();
    }

    const handleClickDeleteUser = idUserToDelete => setIdUserToDelete(idUserToDelete);

    const getAccessCount = (user, zoneDcId) => {
        return user.access?.filter(item => item.zoneDCId === zoneDcId).length;
    }


    return <>
        <Grid container direction="row" justifyContent="space-between" alignItems="center" padding={"20px 0"}>
            <Grid item>
                <Typography variant="h5">Liste des utilisateurs</Typography>
            </Grid>
            <Grid item>
                <Tooltip title="Créer un utilisateur">
                    <IconButton aria-label="create" color="primary" onClick={() => handleClickEditUser(empty_user)}>
                        <AddCircleIcon />
                    </IconButton>
                </Tooltip>

            </Grid>
        </Grid>

        <ShowLoading loading={loading}></ShowLoading>

        <TableContainer component={Paper}>
            <Table aria-label="collab table">
                <TableHead>
                    <TableRow>
                        <TableCell>Id Foncia</TableCell>
                        <TableCell>Nom</TableCell>
                        <TableCell>Profil</TableCell>
                        <TableCell>Accès aux cabinets par région</TableCell>
                        {zonesDc.length > 0 &&  <TableCell>Accès aux cabinets par zone</TableCell>}
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {!loading && users.map((user) => (
                        <TableRow key={user.refIndividu} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">{user.refIndividu}</TableCell>
                            <TableCell>{user.individu?.nom} {user.individu?.prenom}</TableCell>
                            <TableCell>{user.profil}</TableCell>
                            <TableCell>
                                {region_idf} ({user.access?.filter(e => e.refRegion === region_idf).length})
                                , {region_nord_est} ({user.access?.filter(e => e.refRegion === region_nord_est).length})
                                , {region_sud_est} ({user.access?.filter(e => e.refRegion === region_sud_est).length})
                                , {region_grand_ouest} ({user.access?.filter(e => e.refRegion === region_grand_ouest).length})
                            </TableCell>
                           {zonesDc.length > 0 && <TableCell>
                                {zonesDc.map((zoneDc, index) => (
                                    <span key={zoneDc.name}>
                                        {index > 0 && ', '}
                                        {zoneDc.name} ({getAccessCount(user, zoneDc.id)})
                                    </span>
                                ))}
                            </TableCell>}
                            <TableCell>
                                <Tooltip title="Modifier">
                                    <IconButton aria-label="modifier" onClick={() => handleClickEditUser(user)}>
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Supprimer">
                                    <IconButton aria-label="supprimer" onClick={() => handleClickDeleteUser(user.refIndividu)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>

            </Table>
        </TableContainer>

        <ConfirmDeleteUserDialog
            idUserToDelete={idUserToDelete}
            onCloseDialog={onCloseDialog}
            service={service}
            handleOpenSnackbar={handleOpenSnackbar}
        />
    </>;
}

export default UserList;