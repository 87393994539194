import { Box, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import LogoutIcon from '@mui/icons-material/Logout';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import UserIcon from '@mui/icons-material/PermIdentity';
import AddLinkIcon from '@mui/icons-material/AddLink';

import packageJson from '../../package.json';


function LeftDrawer(props) {

    const { oktaAuth, authState } = useOktaAuth();

    const location = useLocation();

    if (!authState || !authState.isAuthenticated) return null;

    let profil = authState.accessToken.profil;

    return <Drawer
        sx={{
            width: props.drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
                width: props.drawerWidth,
                boxSizing: 'border-box',
            },
        }}
        variant="permanent"
        anchor="left">

        <List>
            <ListItem>
                <ListItemIcon><UserIcon /></ListItemIcon>
                <ListItemText primary={authState.idToken?.claims?.name} />
            </ListItem>
        </List>

        <Divider />

        <List component="nav">
            <ListItem>
                <ListItemText primary={<Box fontWeight="fontWeightMedium">Dév. commercial GL</Box>} />
            </ListItem>
            <Divider />
            <ListItemButton component={Link} to="/" selected={location.pathname === '/'}>
                <ListItemIcon><AddLocationAltIcon /></ListItemIcon>
                <ListItemText primary="Secteur des agences" />
            </ListItemButton>

            <ListItemButton component={Link} to="/cd" selected={location.pathname === '/cd'}>
                <ListItemIcon><PersonPinCircleIcon /></ListItemIcon>
                <ListItemText primary="Chargés de dev" />
            </ListItemButton>
            <Divider />
            <ListItem>
                <ListItemText primary={<Box fontWeight="fontWeightMedium">Transaction</Box>} />
            </ListItem>

            <Divider />
            <ListItemButton component={Link} to="/dv" selected={location.pathname === '/dv'}>
                <ListItemIcon><AddLocationAltIcon /></ListItemIcon>
                <ListItemText primary="Affectation DV" />
            </ListItemButton>
            {profil?.profil === 'admin' && 
                <>
                    <Divider />
                    <ListItem>
                        <ListItemText primary={<Box fontWeight="fontWeightMedium">Administration</Box>} />
                    </ListItem>
                    <Divider />
                    <ListItemButton component={Link} to="/affectations" selected={location.pathname === '/affectations'}>
                        <ListItemIcon><AddLinkIcon /></ListItemIcon>
                        <ListItemText primary="Liste des affectations" />
                    </ListItemButton>
                    <ListItemButton component={Link} to="/user/admin" selected={location.pathname === '/user/admin'}>
                        <ListItemIcon><SettingsIcon /></ListItemIcon>
                        <ListItemText primary="Utilisateurs" />
                    </ListItemButton>
                </>

            }
            <Divider />
            <ListItemButton onClick={() => oktaAuth.signOut()}>
                <ListItemIcon><LogoutIcon /></ListItemIcon>
                <ListItemText primary="Déconnexion" />
            </ListItemButton>
        </List>
        <Typography sx={{ pl: 2, mt: 'auto', color: 'text.secondary' }} variant="subtitle2">Frontend: {packageJson.version}</Typography>
        <Typography sx={{ pl: 2, mb: 2, color: 'text.secondary' }} variant="subtitle2">Backend: {profil?.vesrionProjet}</Typography>
    </Drawer>;
}

export default LeftDrawer;