import { Button, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from "@mui/material";
import { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from '@mui/material/InputAdornment';

export default function RemplacerManagerDialog(props) {
    const { onClose, open, collabsEtablissement, source } = props;

    const [newManagerId, setNewManagerId] = useState(null);
    const [rows, setRows] = useState(collabsEtablissement);
    const [searched, setSearched] = useState("");
  
    const requestSearch = (searchedVal) => {
        setSearched(searchedVal);
        const filteredCollabs = collabsEtablissement.filter((collab) => {
            return collab.nom.toLowerCase().includes(searchedVal.toLowerCase()) || collab.prenom.toLowerCase().includes(searchedVal.toLowerCase());
        });
        setRows(filteredCollabs);
    };

    const handleCloseConfirm = () => {
        onClose(newManagerId);
    };

    const handleCloseCancel = (event, reason) => {
        if (reason && reason === "backdropClick") 
        return;
        onClose();
    };

    return (
        <Dialog open={open} >
            <DialogContent>
                <FormControl>
                    <FormLabel id="radio-buttons-group">Sélectionner le nouveau Manager {source}</FormLabel>

                    <TextField
                        id="standard-basic"
                        type="search" 
                        placeholder="NOM Prénom"
                        onChange={(event) => requestSearch(event.target.value)}
                        value={searched}
                        variant="standard"
                        InputProps={{
                            startAdornment: (
                              <InputAdornment>
                                  <SearchIcon />
                              </InputAdornment>
                            )
                        }}
                    />

                    <RadioGroup aria-labelledby="radio-buttons-group" name="managergl-selection"
                        onChange={e => setNewManagerId(e.target.value)}
                    >
                        {rows.map(collab =>
                            <FormControlLabel value={collab.idIndividu} key={collab.idIndividu} control={<Radio />} label={collab.nom + ' ' + collab.prenom} />
                        )}
                    </RadioGroup>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseCancel}>Annuler</Button>
                {newManagerId && <Button onClick={handleCloseConfirm}>Confirmer</Button>}
            </DialogActions>

        </Dialog>
    );
}